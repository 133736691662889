import React from "react";
import { Link, useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import { useAuthenticator } from "@aws-amplify/ui-react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./Layout.css";
import "./AboutUs.css";
import { useLocation } from "react-router-dom";

const pages = [
  { name: "Products", path: "/productnew" },
  { name: "About Us", path: "/aboutus" },
  { name: "Impact", path: "/impact" },
  { name: "Home", path: "/" },
];
const settings = ["Profile", "Account", "Dashboard", "Logout"];

const Layout = ({ children }) => {
  const location = useLocation();
  const excludeFooterPaths = ["/iotd"];
  const includemenu = ["/iotd"];

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const navigate = useNavigate();
  const { user, signOut } = useAuthenticator((context) => [context.user]); // Get signOut function

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleMenuItemClick = async (setting) => {
    if (setting === "Logout") {
      await signOut();
      navigate("/login"); // Redirect to login page after logout
    }
    handleCloseUserMenu();
  };

  const handlePageNavigation = (path) => {
    navigate(path);
    handleCloseNavMenu();
  };

  const handlePrivacyPolicyClick = () => {
    navigate("/PrivacyPolicy");
  };

  return (
    <div>
      <AppBar position="fixed" sx={{ background: "aliceblue" }}>
        <Container maxWidth="xl" className="appbarh">
          <Toolbar disableGutters className="appbarh2">
            <Typography
              variant="h6"
              noWrap
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "black",
                textDecoration: "none",
              }}
            >
              <Link to="/">
                <img
                  src="/Yotuh Logo (1).png"
                  className="logo-img mobile"
                  alt="logo"
                  style={{ height: "30px", marginTop: "5px" }}
                />
              </Link>
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="black"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem
                    key={page.name}
                    onClick={() => handlePageNavigation(page.path)}
                  >
                    <Typography textAlign="center" className="pagesbox">{page.name}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            <Typography
              variant="h5"
              noWrap
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              <Link to="/yotuhhome">
                <img
                  src="/Yotuh Logo (1).png"
                  alt="logo"
                  style={{ height: "30px" }}
                ></img>
              </Link>
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {pages.map((page) => (
                <Button
                  key={page.name}
                  onClick={() => handlePageNavigation(page.path)}
                  sx={{ my: 0, mx: 2, color: "black", display: "block" }}
                  className="layout-menu"
                >
                  {page.name}
                </Button>
              ))}
            </Box>
            {includemenu.includes(location.pathname) && (
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <MenuIcon />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem
                      className="menu-items"
                      key={setting}
                      onClick={() => handleMenuItemClick(setting)}
                    >
                      <Typography textAlign="center">{setting}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            )}
          </Toolbar>
        </Container>
      </AppBar>
      <main style={{ marginTop: "40px" }}>{children}</main>

      {!excludeFooterPaths.includes(location.pathname) && (
        <footer
          className="footerc"
          style={{
            backgroundColor: "#0e121b",
            color: "#fff",
            padding: "20px 0",
            textAlign: "center",
            marginTop: "20px",
          }}
        >
          <Container maxWidth="md">
            <Typography variant="h6" gutterBottom>
              Connect With Us
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
              <a
                href="https://www.facebook.com/people/Yotuh-Energy/100087766609461/"
                style={{ color: "#fff", margin: "0 10px" }}
              >
                <i className="fab fa-facebook-f"></i>
              </a>
              <a
                href="https://www.instagram.com/yotuh_energy/"
                style={{ color: "#fff", margin: "0 10px" }}
              >
                <i className="fab fa-instagram"></i>
              </a>
              <a
                href="https://x.com/YotuhEnergy"
                style={{ color: "#fff", margin: "0 10px" }}
              >
                <i className="fab fa-twitter"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/yotuh-energy/"
                style={{ color: "#fff", margin: "0 10px" }}
              >
                <i className="fab fa-linkedin-in"></i>
              </a>
            </Box>
            <Typography
              style={{ fontSize: "30px" }}
              variant="body1"
              gutterBottom
            >
              Yotuh Energy Pvt Ltd
            </Typography>
            <Typography variant="body2" gutterBottom>
              <Typography variant="h6">Registered Address</Typography>
              97/98, Mahendra Park, Pankha Rd, Uttam Nagar, West Delhi - 110059
            </Typography>
            <Typography variant="body2" gutterBottom>
              <Typography variant="h6">Current Address</Typography>
              01 FA, First floor, IITM Incubation cell, Kanagam Road, Taramani,
              Chennai, Tamil Nadu-600113
            </Typography>
            <br />

            <Typography variant="body2" gutterBottom>
              Email:{" "}
              <a href="mailto:founders@yotuh.com" style={{ color: "#fff" }}>
                founders@yotuh.com
              </a>
            </Typography>
            <Typography variant="body2" gutterBottom>
              Ph: +91-8238320575, +91-8826691930
            </Typography>

            <Typography variant="body2" gutterBottom>
              <span onClick={handlePrivacyPolicyClick} style={{ cursor: "pointer", textDecoration: "underline" }}>
                Privacy policy
              </span>{" "}
              | © Copyright 2024 - Yotuh Energy{" "}
            </Typography>
          </Container>
        </footer>
      )}
    </div>
  );
};

export default Layout;
