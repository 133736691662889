/* eslint-disable jsx-a11y/img-redundant-alt */
import "./YotuhHome.css";
import React, { useEffect, useRef } from "react";
import snow from "../snow-flake-svgrepo-com.png";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import $ from "jquery";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Flex } from "@aws-amplify/ui-react";
// import Flickity from "flickity";
// import "flickity/css/flickity.css";

const YotuhHome = () => {
  const headingRef = useRef(null);
  const weRef = useRef(null);
  const we2Ref = useRef(null);
  const we3Ref = useRef(null);
  const a3Ref = useRef(null);
  const aRef = useRef(null);
  const skewRef = useRef(null);
  const skew2Ref = useRef(null);
  const sheenContainerRef = useRef(null);
  const sheenContainer2Ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (
            entry.target === headingRef.current ||
            entry.target === a3Ref.current
          ) {
            entry.target.classList.add("fade-in");
          } else if (
            entry.target === weRef.current ||
            entry.target === we2Ref.current ||
            entry.target === we3Ref.current ||
            entry.target === aRef.current ||
            entry.target === skewRef.current ||
            entry.target === skew2Ref.current ||
            entry.target === sheenContainerRef.current ||
            entry.target === sheenContainer2Ref.current
          ) {
            console.log(entry.target);
            entry.target.classList.add("fade-right");
          }
          observer.unobserve(entry.target); // Stop observing once the animation is triggered
        }
      });
    });

    if (headingRef.current) observer.observe(headingRef.current);
    if (weRef.current) observer.observe(weRef.current);
    if (we2Ref.current) observer.observe(we2Ref.current);
    if (we3Ref.current) observer.observe(we3Ref.current);
    if (a3Ref.current) observer.observe(a3Ref.current);
    if (aRef.current) observer.observe(aRef.current);
    if (skewRef.current) observer.observe(skewRef.current);
    if (skew2Ref.current) observer.observe(skew2Ref.current);
    if (sheenContainerRef.current) observer.observe(sheenContainerRef.current);
    if (sheenContainer2Ref.current)
      observer.observe(sheenContainer2Ref.current);

    return () => {
      if (headingRef.current) observer.unobserve(headingRef.current);
      if (weRef.current) observer.unobserve(weRef.current);
      if (we2Ref.current) observer.unobserve(we2Ref.current);
      if (we3Ref.current) observer.unobserve(we3Ref.current);
      if (a3Ref.current) observer.unobserve(a3Ref.current);
      if (aRef.current) observer.unobserve(aRef.current);
      if (skewRef.current) observer.unobserve(skewRef.current);
      if (skew2Ref.current) observer.unobserve(skew2Ref.current);
      if (sheenContainerRef.current)
        observer.unobserve(sheenContainerRef.current);
      if (sheenContainer2Ref.current)
        observer.unobserve(sheenContainer2Ref.current);
    };
  }, []);

  const cardRefs = useRef([]);

  const addToRefs = (el) => {
    if (el && !cardRefs.current.includes(el)) {
      cardRefs.current.push(el);
    }
  };

  useEffect(() => {
    cardRefs.current.forEach((card) => {
      card.style.opacity = 1; // Show the card
    });
  }, []);


  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.style.opacity = 1;
          entry.target.style.transition = "opacity 2s ease-in-out"; // Fade-in transition
        }
      });
    });

    cardRefs.current.forEach((card) => {
      observer.observe(card);
    });

    return () => {
      cardRefs.current.forEach((card) => {
        observer.unobserve(card);
      });
    };
  }, []);

  useEffect(() => {
    const snowflakes = document.querySelectorAll(".snow");
    snowflakes.forEach((snowflake) => {
      window.addEventListener("scroll", () => {
        requestAnimationFrame(() => {
          const rotationAngle = window.scrollY * 0.3;
          snowflake.style.transform = `rotate(${rotationAngle}deg)`;
        });
      });
    });
  }, []);

  // const flickityRef = useRef(null);

  // useEffect(() => {
  //   const flkty = new Flickity(flickityRef.current, {
  //     wrapAround: true,
  //   });

  //   // Cleanup
  //   return () => flkty.destroy();
  // }, []);

  const WrapperSlide = () => {
    return (
      <div className="wrapper-slide">
        <div className="items">
          <div
            className="logogrp"
            style={{ display: "flex", flexDirection: "row", marginLeft: "15%" }}
          >
            <a
              href="http://rtbi.in/incubationiitm/home.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/IITM incubation cell.png"
                className="sponsorslogo"
                alt="logo"
                style={{ height: "100px" }}
              />
            </a>
            <a
              href="https://asia.daimlertruck.com/en/about-us/business-unit/dicv/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/image-removebg-preview.png"
                className="sponsorslogo"
                alt="logo"
                style={{ height: "100px" }}
              />
            </a>
          </div>

          <h4 style={{ textAlign: "center", marginTop: "5%" }}>
            {" "}
            Incubated at IIT MADRAS incubation cell and DICV
          </h4>
        </div>

        <div className="items">
          <a
            href="https://paupabiraftaar.co.in/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div
              className="logogrp"
              style={{ display: "flex", flexDirection: "row" }}
            >
              <img
                src="/Punjab_Agricultural_University_logo.png"
                className="sponsorslogo"
                alt="logo"
                style={{ height: "100px", paddingRight: "20px" }}
              />

              <img
                src="/Ministry_of_Agriculture_India.svg.png"
                className="sponsorslogo"
                alt="logo"
                style={{ height: "100px" }}
              />
            </div>
          </a>

          <h4 style={{ textAlign: "center", marginTop: "5%" }}>
            {" "}
            RKVY RAFTAAR program
          </h4>
        </div>
        <div className="items">
          <div className="items">
            <a
              href="https://www.startupnexus.in/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div
                className="logogrp"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <img
                  src="/Nexus-Logo-Transparent.png"
                  className="sponsorslogo"
                  alt="logo"
                  style={{ height: "110px" }}
                />
                <img
                  src="/imgacir-removebg-preview.png"
                  className="sponsorslogo"
                  alt="logo"
                  style={{ height: "100px", marginRight: "10px" }}
                />
                <img
                  src="/US Embassy logo.png"
                  className="sponsorslogo"
                  alt="logo"
                  style={{ height: "100px" }}
                />
              </div>
            </a>

            <h4 style={{ textAlign: "center", marginTop: "5%" }}>
              {" "}
              NEXUS ACIR alumni
            </h4>
          </div>
        </div>
        <div className="items">
          <a
            href="https://iimuic.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div
              className="logogrp"
              style={{ display: "flex", flexDirection: "row" }}
            >
              <img
                src="/Indian_Institute_of_Management_Udaipur_Logo.png"
                className="sponsorslogo"
                alt="logo"
                style={{ height: "100px", padding: "2%" }}
              />
              <img
                src="/Ministry_of_Science_and_Technology_India.svg.png"
                className="sponsorslogo"
                alt="logo"
                style={{ height: "100px", paddingLeft: "20px" }}
              />
            </div>
          </a>

          <h4 style={{ textAlign: "center", marginTop: "5%" }}>
            {" "}
            NIDHI - EIR IIM Udaipur
          </h4>
        </div>

        <div className="items">
          <div
            className="logogrp"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <a
              href="https://acumen.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="5afabc42e0520ad44874cda399542d72_400x400.png"
                className="sponsorslogo"
                alt="logo"
                style={{ height: "100px", alignItems: "center" }}
              />
            </a>
          </div>

          <h4
            style={{ textAlign: "center", minWidth: "200px", marginTop: "5%" }}
          >
            {" "}
            Acumen: The energy for livelihood program
          </h4>
        </div>
        <div className="items">
          <div
            className="logogrp"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <a
              href="https://ivycapventures.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/Screenshot_2024-06-06_140357-removebg-preview.png"
                className="sponsorslogo"
                alt="logo"
                style={{ height: "100px", alignItems: "center" }}
              />
            </a>
          </div>

          <h4
            style={{ textAlign: "center", minWidth: "200px", marginTop: "5%" }}
          >
            {" "}
            “The next startup dragon” award
          </h4>
        </div>
        <div className="items">
          <div
            className="logogrp"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <a
              href="https://iitstartups.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/IIT-Startups-logo-16-9-removebg-preview.png"
                className="sponsorslogo"
                alt="logo"
                style={{ height: "100px", alignItems: "center" }}
              />
            </a>
          </div>

          <h4
            style={{ textAlign: "center", minWidth: "200px", marginTop: "5%" }}
          >
            {" "}
            IIT Startups Alumni
          </h4>
        </div>
        <div className="items">
          <a
            href="https://www.undp.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div
              className="logogrp"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <img
                src="/UNDP_logo-removebg-preview.png"
                className="sponsorslogo"
                alt="logo"
                style={{ height: "100px", alignItems: "center" }}
              />
              <img
                src="/Renew power logo.png"
                className="sponsorslogo"
                alt="logo"
                style={{
                  height: "40px",
                  alignItems: "center",
                  marginTop: "8%",
                }}
              />
            </div>
          </a>
          <h4
            style={{ textAlign: "center", minWidth: "200px", marginTop: "5%" }}
          >
            {" "}
            UNDP Accelerator & Green Tech accelerator program
          </h4>
        </div>
        <div className="items">
          <a
            href="https://dst.gov.in/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div
              className="logogrp"
              style={{ display: "flex", flexDirection: "row" }}
            >
              <img
                src="/Ministry_of_Science_and_Technology_India.svg.png"
                className="sponsorslogo"
                alt="logo"
                style={{ height: "100px" }}
              />
              <img
                src="/FiiRE-logo-Transparent-1.png"
                className="sponsorslogo"
                alt="logo"
                style={{ height: "100px", paddingLeft: "5%" }}
              />
            </div>
          </a>
          <h4 style={{ textAlign: "center", marginTop: "5%" }}>
            {" "}
            NIDHI - PRAYAS Grant
          </h4>
        </div>
        <div className="items">
          <div
            className="logogrp"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <a
              href="https://iimuic.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/tide2.png"
                className="sponsorslogo"
                alt="logo"
                style={{ height: "100px" }}
              />
            </a>
          </div>

          <h4 style={{ textAlign: "center", marginTop: "5%" }}>
            {" "}
            TiDE 2.0 -IIM Visakhaptnam
          </h4>
        </div>
        <div className="items">
          <a
            href="https://www.iitm.ac.in/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div
              className="logogrp"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <img
                src="/IIT_Madras_Logo.svg.png"
                className="sponsorslogo"
                alt="logo"
                style={{
                  height: "100px",
                  alignItems: "center",
                  padding: "5px",
                }}
              />
              <img
                src="/Screenshot_2024-06-06_222724-removebg-preview.png"
                className="sponsorslogo"
                alt="logo"
                style={{
                  height: "100px",
                  alignItems: "center",
                  paddingLeft: "20px",
                }}
              />
            </div>
          </a>
          <h4
            style={{ textAlign: "center", minWidth: "200px", marginTop: "5%" }}
          >
            {" "}
            IIT Madras – CZC4.0 Winner{" "}
          </h4>
        </div>
        <div className="items">
          <div
            className="logogrp"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <img
              src="/IIT Delhi black logo.png"
              className="sponsorslogo"
              alt="logo"
              style={{ height: "100px", alignItems: "center" }}
            />
            <img
              src="/Screenshot_2024-06-06_222732-removebg-preview.png"
              className="sponsorslogo"
              alt="logo"
              style={{ height: "100px", alignItems: "center" }}
            />
          </div>

          <h4
            style={{ textAlign: "center", minWidth: "200px", marginTop: "5%" }}
          >
            {" "}
            IIT Delhi Alumni Network{" "}
          </h4>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="YunitHomeContainer">
        <div className="imageContainer">
          <img src="/yotuhUnit.jpg" className="YunitHome" alt="logo" />
          <div className="overlay">
            <img className="whitelogo" src="/whitelogo.png" alt="logo" />
            <div className="hometext">
              <h1>YOTUH ENERGY</h1>
              <h2>BUILDING FUTURE OF COLDCHAIN REFRIGERATION</h2>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="snowflake">
        <img
          src={snow}
          className="snow"
          alt="snowlogo"
          style={{ height: "80px" }}
        ></img>
      </div>

      <div className="snowflake" style={{ paddingLeft: "10%" }}>
        <img
          src={snow}
          className="snow"
          alt="snowlogo"
          style={{ height: "20px" }}
        ></img>
      </div>
      <div className="snowflake" style={{ paddingTop: "8%" }}>
        <img
          src={snow}
          className="snow"
          alt="snowlogo"
          style={{ height: "40px" }}
        ></img>
      </div> */}

      <div className="container2">
        <h2 ref={headingRef} className="teamh2">
          योतु: - ACT OF CLEANING
        </h2>

        <div className="we" ref={weRef} style={{ width: "fitContent" }}>
          <div className="usImg" ref={we2Ref}>
            <img
              src="/TeamYotuh_Photo1.jpg"
              className="Team"
              alt="logo"
              // style={{ borderRadius: "30px", boxShadow: "" }}
            ></img>
          </div>
          {/* <div className="line">
          <img src="/line-xl-svgrepo-com.png" alt="line"></img>
        </div> */}
          <div className="usData" ref={we3Ref}>
            <p className="para2" style={{ textAlign: "justify" }}>
              {" "}
              We are a team of Hustlers, Hackers and Hipsters who are passionate
              about solving the climate problem by building sustainable
              technologies in India. We are blending our passion for building
              technologies into creating a long lasting impact in society.
              Coming from diverse backgrounds and cultures, at Yotuh, we are
              working together to bring a change in the way we use energy.
            </p>
            <p
              
              style={{ paddingTop: "20px", textAlign: "justify" }}
            >
              At Yotuh Energy, we are bringing the energy shift to the Cold
              chain. As a clean tech start-up, we are electrifying cold chain
              refrigeration for logistics. Our Vision is to make the cold chain
              accessible, affordable and sustainable. With our technology, we
              are aiming to increase the cold chain penetration in India so that
              the perishable food loss can be curbed and livelihoods can be
              improved.
            </p>
          </div>
        </div>
      </div>

      {/* <div className="container">
        <div className="box">
          <div className="content">
            <h2>01</h2>
            <h3>Accessible</h3>
            <p>Any type of vehicle can be refirgerated</p>
            <p>·</p>
            <p>· </p>
            <a href="#">details</a>
          </div>
        </div>

        <div className="box">
          <div className="content">
            <h2>02</h2>
            <h3>Affordable</h3>
            <p>Reducing the cost of perishable transport.</p>
            <p>· </p>
            <p>· .</p>
            <a href="#">details</a>
          </div>
        </div>

        <div className="box">
          <div className="content" >
            <h2>03</h2>
            <h3>Sustainable</h3>
            <p></p>
            <p>· </p>
            <p>· </p>
            <a href="#">Details</a>
          </div>
        </div>
      </div> */}

      <div className="sheen-container-start-anchor" ref={sheenContainerRef} />
      <div class="sheen-container">
        <img src="/yotuhFrio.png" alt="Background Image" />
        <img src="yotuhFrio2.png" alt="Overlay Image" class="overlayI" />
        <div className="sheen-container-heading-overlay-text">
          <div className="heading1">INTRODUCING</div>
          <div className="heading2" ref={sheenContainer2Ref}>
            SHEEN 2.0
          </div>
        </div>
        <div className="sheen-container-overlay-text">
          <div
            className="hello"
            style={{
              animationDelay: "1.2s",
              fontWeight: "400",
              paddingLeft: "0px",
            }}
          >
            <img
              src="/productimg/tick-circle-svgrepo-com (1).png"
              alt=""
              style={{ height: "20px", paddingRight: "20px" }}
            />
            <h4 style={{ fontWeight: 200, margin: 0 }}>100% ELECTRIC </h4>
          </div>

          <div
            className="hello"
            style={{
              paddingLeft: "60px",
              animationDelay: "1.4s",
              fontWeight: "400",
            }}
          >
            <img
              src="/productimg/tick-circle-svgrepo-com (1).png"
              alt=""
              style={{ height: "20px", paddingRight: "20px" }}
            />
            <h4 style={{ fontWeight: 200, margin: 0 }}>STANDBY POWER</h4>
          </div>
          <div
            className="hello"
            style={{
              paddingLeft: "100px",
              animationDelay: "1.6s",
              fontWeight: "400",
            }}
          >
            <img
              src="/productimg/tick-circle-svgrepo-com (1).png"
              alt=""
              style={{ height: "20px", paddingRight: "20px" }}
            />
            <h4 style={{ fontWeight: 200, margin: 0 }}>EFFICIENT & FAST</h4>
          </div>
          <div
            className="hello"
            style={{
              paddingLeft: "80px",
              animationDelay: "1.8s",
              fontWeight: "400",
            }}
          >
            <img
              src="/productimg/tick-circle-svgrepo-com (1).png"
              alt=""
              style={{ height: "20px", paddingRight: "20px" }}
            />
            <h4 style={{ fontWeight: 200, margin: 0 }}> ADAPTIVE COOLING </h4>
          </div>
          <div
            className="hello"
            style={{
              animationDelay: "1.2s",
              fontWeight: "400",
              paddingLeft: "60px",
            }}
          >
            <img
              src="/productimg/tick-circle-svgrepo-com (1).png"
              alt=""
              style={{ height: "20px", paddingRight: "20px" }}
            />
            <h4 style={{ fontWeight: 200, margin: 0 }}>LIVE MONITORING</h4>
          </div>
        </div>
      </div>

      <div className="homemobdiv"><img className="homemob.png"
        src="/homemob.png"
        alt=""
      /></div>

      <div className="a3-container">
        <h2 className="a3-heading" ref={a3Ref}>
          We envision to make Cold chain
        </h2>
        <div className="subheading">
          <span className="highlight" ref={aRef}>
            Accessible <span className="separator"> | </span> Affordable{" "}
            <span className="separator"> | </span> Sustainable
          </span>
        </div>
      </div>
      {/* <div className="sheen">
        <img src="/yotuhFrio.png" alt="sheen" />
        <div className="overlay1">
          <div className="whitelogotext">INTRODUCING</div>
          <div className="hometext" style={{ paddingBottom: " 50px" }}>
            <h1>SHEEN 2.0</h1>
          </div>
        </div> */}
      {/* <div className="sheendiv">
          <div className="gallery" ref={flickityRef}>
            <div className="gallery-cell"></div>
            <div className="gallery-cell"></div>
            <div className="gallery-cell"></div>
            <div className="gallery-cell"></div>
            <div className="gallery-cell"></div>
          </div>
        </div> */}
      {/* </div> */}

      {/* <div className="coloredboxcontainer" style={{backgroundColor:""}}>
        <div className="skewclass">
          <div className="skew">
            <div className="content-area top-left" style={{ paddingTop: 0 }}>
              <h2 style={{ marginBottom: "20px" }}>INTRODUCING SHEEN 2.0</h2>
              <div className="points">
                <Card
                  ref={addToRefs}
                  sx={{
                    width: "45%",
                    backgroundColor: "transparent",
                    border: "solid 1px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    borderRadius: "20px",
                    borderColor: "transparent",
                    textAlign: "center",
                    opacity: 0,
                    transition: "transform 0.3s ease",
                    ":hover": {
                      transform: "scale(1.02)",
                    },
                  }}
                >
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 24, fontWeight: "bold", color: "green" }}
                      color="text.secondary"
                    ></Typography>
                    <Typography>
                      Faster cooling & efficient Multipoint deliveries possible
                    </Typography>
                  </CardContent>
                </Card>

                <Card
                  ref={addToRefs}
                  sx={{
                    width: "45%",
                    backgroundColor: "transparent",
                    border: "solid 1px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    borderRadius: "20px",
                    borderColor: "transparent",
                    textAlign: "center",
                    opacity: 0,
                    transition: "transform 0.3s ease",
                    ":hover": {
                      transform: "scale(1.02)",
                    },
                  }}
                >
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 24, fontWeight: "bold", color: "green" }}
                      color="text.secondary"
                    ></Typography>
                    <Typography>
                      Saves up to Rs. 75,000 annually 0% fuel use
                    </Typography>
                  </CardContent>
                </Card>

                <Card
                  ref={addToRefs}
                  sx={{
                    width: "45%",
                    backgroundColor: "transparent",
                    border: "solid 1px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    borderRadius: "20px",
                    borderColor: "transparent",
                    textAlign: "center",
                    opacity: 0,
                    transition: "transform 0.3s ease",
                    ":hover": {
                      transform: "scale(1.02)",
                    },
                  }}
                >
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 24, fontWeight: "bold", color: "green" }}
                      color="text.secondary"
                    ></Typography>
                    <Typography>
                      Faster cooling & efficient Multipoint deliveries possible
                      use
                    </Typography>
                  </CardContent>
                </Card>
                <Card
                  ref={addToRefs}
                  sx={{
                    width: "45%",
                    backgroundColor: "transparent",
                    border: "solid 1px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    borderRadius: "20px",
                    borderColor: "transparent",
                    textAlign: "center",
                    opacity: 0,
                    transition: "transform 0.3s ease",
                    ":hover": {
                      transform: "scale(1.02)",
                    },
                  }}
                >
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 24, fontWeight: "bold", color: "green" }}
                      color="text.secondary"
                    ></Typography>
                    <Typography>
                      Only 3.5 Hrs for 0 to 100% charge use
                    </Typography>
                  </CardContent>
                </Card>
              </div>
            </div>
            <div className="bg-image top-right" ref={skewRef}></div>
            <div className="bg-image bottom-left" ref={skew2Ref}></div>
            <div
              className="content-area bottom-right"
              style={{ paddingRight: 0 }}
            >
              <h2 style={{ marginBottom: "20px" }}>FEATURES</h2>
              <div className="points">
                <Card
                  ref={addToRefs}
                  sx={{
                    width: "45%",
                    backgroundColor: "transparent",
                    border: "solid 1px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    borderRadius: "20px",
                    borderColor: "transparent",
                    textAlign: "center",
                    opacity: 0,
                    transition: "transform 0.3s ease",
                    ":hover": {
                      transform: "scale(1.02)",
                    },
                  }}
                >
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 24, fontWeight: "bold", color: "green" }}
                      color="text.secondary"
                    ></Typography>
                    <Typography>25% more efficient and 2 times fast</Typography>
                  </CardContent>
                </Card>

                <Card
                  ref={addToRefs}
                  sx={{
                    width: "45%",
                    backgroundColor: "transparent",
                    border: "solid 1px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    borderRadius: "20px",
                    borderColor: "transparent",
                    textAlign: "center",
                    opacity: 0,
                    transition: "transform 0.3s ease",
                    ":hover": {
                      transform: "scale(1.02)",
                    },
                  }}
                >
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 24, fontWeight: "bold", color: "green" }}
                      color="text.secondary"
                    ></Typography>
                    <Typography>
                      Temperature monitoring, data logging & communications
                    </Typography>
                  </CardContent>
                </Card>

                <Card
                  ref={addToRefs}
                  sx={{
                    width: "45%",
                    backgroundColor: "transparent",
                    border: "solid 1px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    borderRadius: "20px",
                    borderColor: "transparent",
                    textAlign: "center",
                    opacity: 0,
                    transition: "transform 0.3s ease",
                    ":hover": {
                      transform: "scale(1.02)",
                    },
                  }}
                >
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 24, fontWeight: "bold", color: "green" }}
                      color="text.secondary"
                    ></Typography>
                    <Typography>
                      Refrigeration can be run directly through grid electricity
                    </Typography>
                  </CardContent>
                </Card>
                <Card
                  ref={addToRefs}
                  sx={{
                    width: "45%",
                    backgroundColor: "transparent",
                    border: "solid 1px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    borderRadius: "20px",
                    borderColor: "transparent",
                    textAlign: "center",
                    opacity: 0,
                    transition: "transform 0.3s ease",
                    ":hover": {
                      transform: "scale(1.02)",
                    },
                  }}
                >
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 24, fontWeight: "bold", color: "green" }}
                      color="text.secondary"
                    ></Typography>
                    <Typography>
                      Error detection, Safety alerts, Lower maintenance
                    </Typography>
                  </CardContent>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="maincontentsli">
        <div className="content-slider">
          <h4 className="statw" style={{ color: "white" }}>
            Redefining the way
          </h4>
          <div className="slider">
            <div className="mask" style={{ color: "white" }}>
              <ul>
                <li className="anim1">
                  <div className="quote">
                    <span>Dairy products</span> are transported.
                  </div>
                </li>
                <li className="anim2">
                  <div className="quote">
                    <span>Sea food</span> is transported.
                  </div>
                </li>
                <li className="anim3">
                  <div className="quote">
                    <span>Ice-Cream</span> is transported.
                  </div>
                </li>
                <li className="anim4">
                  <div className="quote">
                    <span>Pharma</span> is transported.
                  </div>
                </li>
                <li className="anim5">
                  <div className="quote">
                    <span>Frozen food</span> is transported.
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="valuep">
        <div className="values">
          
        </div>
      </div> */}
      <div className="app">
      <div
        ref={addToRefs}
        className="card card-reduction"
      >
        <div className="card-content">
          <img
            src="truck-delivery-svgrepo-com.png"
            alt="Truck Delivery"
            className="card-icon"
          />
          <div className="card-title">75% Reduction</div>
          <div className="card-description">in refrigeration running cost and maintenance</div>
        </div>
      </div>

      <div
        ref={addToRefs}
        className="card card-ease-of-use"
      >
        <div className="card-content">
          <img
            src="crop-simple-svgrepo-com.png"
            alt="Crop Simple"
            className="card-icon"
          />
          <div className="card-title">Ease of use</div>
          <div className="card-description">can be achieved by attaching it to ANY SCVs and EVs.</div>
        </div>
      </div>

      <div
        ref={addToRefs}
        className="card card-savings"
      >
        <div className="card-content">
          <img
            src="savings-money-svgrepo-com.png"
            alt="Savings Money"
            className="card-icon"
          />
          <div className="card-title">35% Savings</div>
          <div className="card-description">on Total Cost Of ownership than Diesel AC</div>
        </div>
      </div>

      <div
        ref={addToRefs}
        className="card card-environment"
      >
        <div className="card-content">
          <img
            src="globe-svgrepo-com.png"
            alt="Globe"
            className="card-icon"
          />
          <div className="card-title">3-36 tons</div>
          <div className="card-description">of CO2 can be offset, thereby saving the environment.</div>
        </div>
      </div>
    </div>

      <div
        className="wrapperContainer"
        style={{ overflow: "hidden", zIndex: 0, position: "relative" }}
      >
        <div
          className="sSnowf"
          style={{ overflow: "hidden", zIndex: 3, position: "relative" }}
        >
          {/* <div className="snowflake" style={{ paddingLeft: "90%" }}>
            <img
              src={snow}
              className="snow"
              alt="snowlogo"
              style={{ height: "20px" }}
            />
          </div> */}
          <h1 className="supportH">We are supported by</h1>

          <div className="wrapper">
            <WrapperSlide />
            <WrapperSlide />
          </div>
        </div>
      </div>

      {/* <div className="snowflake">
        <img
          src={snow}
          className="snow"
          alt="snowlogo"
          style={{ height: "40px" }}
        ></img>
      </div> */}
    </>
  );
};

export default YotuhHome;
