import React from "react";
import "./Impact.css";

const Impact = () => {
  return (
    <>
      <div className="sdg">
        <img
          src="/impactimg/sdg.png"
          alt="impactLogo"
          className="impact-image_"
        />
        <a href="https://www.un.org/sustainabledevelopment/sustainable-development-goals/">
        <div className="sgds">
        <img
            src="/impactimg/1SDG.png"
            alt="impactLogo"
            className="impact-image1"
            style={{ margin: "2%" }}
          />          
          <img
            src="/impactimg/SDG9.png"
            alt="impactLogo"
            className="impact-image1"
            style={{ margin: "2%" }}
          />
          
          <img
            src="/impactimg/2SDG.png"
            alt="impactLogo"
            className="impact-image1"
            style={{ margin: "2%"}}
          />
        </div>
        </a>
      </div>
      {/* <h5 className="sdg-title">SDG 2.3.2 Zero Hunger</h5>
    <h5 className="sdg-title">SDG 12.3.1 Responsible Consumption and Production</h5> */}

      <div className="ImpactContainer">
        <div className="impactcard">
          <div className="impc" style={{ paddingTop: "5px" }}>
            <div className="impact1">
              <img
                src="/impactimg/vegetables.png"
                alt="impactLogo"
                className="impact-image"
              />
            </div>
            <div className="impact-description">
              <div className="impactf">
                <img
                  src="/impactimg/1231.png"
                  alt="impactLogo"
                  className="impactfimg"
                />
                <h3>Enabling cold chain For low economic value goods</h3>
              </div>
            </div>
          </div>
          <div className="impc" style={{ paddingBottom: "5px" }}>
            <div className="impact-description">
              <div className="impactf">
                <img
                  src="/impactimg/1231.png"
                  alt="impactLogo"
                  className="impactfimg"
                />
                <h3>
                  Reducing the perishable food loss due to lack of <br/>cold chain 
                </h3>

              </div>
            </div>

            <div className="impact1">
              <img
                src="/impactimg/vegGarbage.png"
                alt="impactLogo"
                className="impact-image"
              />
            </div>
          </div>
          <div className="impc" style={{ paddingTop: "5px" }}>
            <div className="impact1">
              <img
                src="/impactimg/Vehicle...png"
                alt="impactLogo"
                className="impact-image"
              />
            </div>
            <div className="impact-description">
              <div className="impactf">
                <img
                  src="/impactimg/941.png"
                  alt="impactLogo"
                  className="impactfimg"
                />
                <h3>
                  With our 100% Electric AC, we can offset 3000 to 36000 kg of
                  CO2 per year
                </h3>
              </div>
            </div>
          </div>

          <div className="impc" style={{ paddingBottom: "0px" }}>
            <div className="impact-description">
              <div className="impactf">
                <img
                  src="/impactimg/232.png"
                  alt="impactLogo"
                  className="impactfimg"
                />
                <h3>Increasing the profit margins of vehicle owners</h3>
              </div>
            </div>
            <div className="impact1">
              <img
                src="/impactimg/farmerMoney.png"
                alt="impactLogo"
                className="impact-image"
              />
            </div>
          </div>
          <div className="impc" style={{ paddingTop: "5px" }}>
            <div className="impact1">
              <img
                src="/impactimg/farmer.png"
                alt="impactLogo"
                className="impact-image"
              />
            </div>
            <div className="impact-description">
              <div className="impactf">
                <img
                  src="/impactimg/232.png"
                  alt="impactLogo"
                  className="impactfimg"
                />
                <h3>
                  Helping farmers and low-income communities to expand their
                  businesses
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Impact;
