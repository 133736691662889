import React, { useState, useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import "./Product.css";

const Product = () => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Trigger the animation only once
    threshold: 0.1, // Trigger when 10% of the component is in view
  });

  const overtRef = useRef(null);
  const pcardRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (
            entry.target === overtRef.current ||
            entry.target === pcardRef.current
          ) {
            entry.target.classList.add("fade-in");
          }
          //   observer.unobserve(entry.target);
        }
      });
    });
    if (overtRef.current) observer.observe(overtRef.current);
    if (pcardRef.current) observer.observe(pcardRef.current);
    return () => {
      if (overtRef.current) observer.unobserve(overtRef.current);
      if (pcardRef.current) observer.unobserve(pcardRef.current);
    };
  }, []);

  const textRefs = useRef([]);

  textRefs.current = [];

  const addToRefs = (el) => {
    if (el && !textRefs.current.includes(el)) {
      textRefs.current.push(el);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.style.opacity = 1;
          entry.target.style.transition = "opacity 2s ease-in-out"; // Fade-in transition
        }
      });
    });

    textRefs.current.forEach((card) => {
      observer.observe(card);
    });

    return () => {
      textRefs.current.forEach((card) => {
        observer.unobserve(card);
      });
    };
  }, []);

  //

  const [isMoving, setIsMoving] = useState(false);
  const stepWrapperRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsMoving(true);
          observer.disconnect(); // Stop observing after the animation starts
        }
      },
      { threshold: 0.1 } // Trigger when 10% of the component is visible
    );

    if (stepWrapperRef.current) {
      observer.observe(stepWrapperRef.current);
    }

    return () => {
      if (observer && stepWrapperRef.current) {
        observer.unobserve(stepWrapperRef.current);
      }
    };
  }, []);

  return (
    <>
      <div className="ProductContainer" style={{paddingRight:0}}>
      <div ref={ref} className={`container ${inView ? "in-view" : ""}`}>
        <h2>
          Our Solution – <span className="highlight">100% Electric</span>{" "}
          Refrigeration Unit
        </h2>
        <div className="image-container">
          <div
            className={`unit-label condenser ${inView ? "slide-in" : ""}`}
            style={{ width: "20%"}}
          >
            <img
              src="/productimg/condens.jpg"
              style={{ width: "100%"}}
              alt="Condenser unit"
            />
            <p>Condenser unit</p>
          </div>
          <div
            className={`unit-label control ${inView ? "slide-in" : ""}`}
            style={{ width: "20%"}}
          >
            <img
              src="/productimg/controlunit.jpg "
              alt="Control unit"
              style={{ width: "100%"}}
            />
            <p>interactive Driver Interface</p>
          </div>
          <img
            src="/productimg/vehicle-removebg-preview.png"
            alt="Refrigerated SCV Truck"
            className={`main-image ${inView ? "fade-in" : ""}`}
            style={{
              width: "60%",
              paddingTop: "6%",
              paddingBottom: "6%",
              marginLeft: "10%",
            }}
          />
          <div
            className={`unit-label1 evaporator ${inView ? "slide-in" : ""}`}
            style={{ width: "20%" }}
          >
            <img
              src="/productimg/evap.PNG"
              alt="Evaporator unit"
              style={{ width: "100%" }}
            />
            <p>Evaporator unit</p>
          </div>
          <div
            className={`unit-label1 puf ${inView ? "slide-in" : ""}`}
            style={{ width: "20%"}}
          >
            <img
              src="/productimg/PCB-Heatsink-jpg.webp"
              alt="Evaporator unit"
              style={{ width: "100%"}}
            />
            <p>Adaptive cooling controls</p>
          </div>
        </div>

        <div className="spinner">
          <div className="spinner-wrap">
            <div className="spinner-item"></div>
            <div className="spinner-item spinner-item--2"></div>
            <div className="spinner-item spinner-item--3"></div>
            <div className="spinner-text">TECHNOLOGY DESCRIPTION</div>
            <div className="ovtcontainer">
            <div className="overlay-text" ref={overtRef}>
              <div
                className="hello"
                style={{ animationDelay: "1.2s", fontWeight: "400" }}
              >
                <img
                  src="/productimg/tick-circle-svgrepo-com (1).png"
                  alt=""
                  style={{ height: "20px", paddingRight: "20px" }}
                ></img>
                <h4 style={{ fontWeight: 200, margin: 0 }}>STANDBY POWER</h4>
              </div>

              <div
                className="hello"
                style={{
                  paddingLeft: "30px",
                  animationDelay: "1.4s",
                  fontWeight: "400",
                }}
              >
                <img
                  src="/productimg/tick-circle-svgrepo-com (1).png"
                  alt=""
                  style={{ height: "20px", paddingRight: "20px" }}
                ></img>
                <h4 style={{ fontWeight: 200, margin: 0 }}>EFFICIENT & FAST</h4>
              </div>
              <div
                className="hello"
                style={{
                  paddingLeft: "45px",
                  animationDelay: "1.6s",
                  fontWeight: "400",
                }}
              >
                <img
                  src="/productimg/tick-circle-svgrepo-com (1).png"
                  alt=""
                  style={{ height: "20px", paddingRight: "20px" }}
                ></img>
                <h4 style={{ fontWeight: 200, margin: 0 }}>ADAPTIVE COOLING</h4>
              </div>
              <div
                className="hello"
                style={{
                  paddingLeft: "30px",
                  animationDelay: "1.8s",
                  fontWeight: "400",
                }}
              >
                <img
                  src="/productimg/tick-circle-svgrepo-com (1).png"
                  alt=""
                  style={{ height: "20px", paddingRight: "20px" }}
                ></img>
                <h4 style={{ fontWeight: 200, margin: 0 }}>SMART SYSTEM</h4>
              </div>
              <div
                className="hello"
                style={{
                  animationDelay: "2s",
                  fontWeight: "400",
                }}
              >
                <img
                  src="/productimg/tick-circle-svgrepo-com (1).png"
                  alt=""
                  style={{ height: "20px", paddingRight: "20px" }}
                ></img>
                <h4 style={{ fontWeight: 200, margin: 0 }}>
                  PREVENTIVE MAINTENANCE
                </h4>
              </div>
            </div>
            </div>
            
          </div>
        </div>

        <div className="benefits" ref={pcardRef}>
        <div className={`benefit-item ${inView ? "slide-in-bottom" : ""}`}>
          <div className="logosp">
            <img
              src="/productimg/fuel-free-fossil-svgrepo-com.png"
              alt=""
              style={{ height: "60px" }}
            ></img>
          </div>
          <div className="logop">
          <p>
            <strong>4-5L</strong> of fuel replaced with <strong>7-8</strong>{" "}
            units of electricity
          </p>
          </div>
          
        </div>
        <div className={`benefit-item ${inView ? "slide-in-bottom" : ""}`}>
          <div className="logosp">
            <img
              src="/productimg/money-svgrepo-com.png"
              alt=""
              style={{ height: "60px" }}
            ></img>
          </div>
          <div className="logop">
          <p>
            <strong>25-30%</strong> Cost reduced for refrigerated transport
          </p>
          </div>
          
        </div>
        <div className={`benefit-item ${inView ? "slide-in-bottom" : ""}`}>
          <div className="logosp">
            <img
              src="/productimg/money-manager-svgrepo-com.png"
              alt=""
              style={{ height: "60px",paddingTop: "15px" }}
            ></img>
          </div>
          <div className="logop">
          <p>
            Reduced TCO By upto <strong>35%</strong>
          </p>
          </div>
          
        </div>
        <div className={`benefit-item ${inView ? "slide-in-bottom" : ""}`}>
          <div className="logosp">
            <img
              src="/productimg/electric-cargo-truck-2-svgrepo-com.png"
              alt=""
              style={{ height: "60px" }}
            ></img>
          </div>
          <div className="logop">
          <p>Freedom to install in any vehicle</p>
          </div>
          
        </div>
      </div>
      </div>

      {/* <div className="containerp">
        <h1>Progress & Roadmap</h1>
        <div
          className={`step-wrapper ${isMoving ? "move-line" : ""}`}
          ref={stepWrapperRef}
        >
          <article
            className="linestep linestep1"
            style={{ left: "56%", top: "-2%" }}
          >
            <span className="num">1</span>
            <p className="bottom">helooooo</p>
          </article>

          <article
            className="linestep linestep2"
            style={{ right: "-0.5%", top: "20%" }}
          >
            <span className="num">2</span>
            <p className="left">helooooo</p>
          </article>

          <article
            className="linestep linestep3"
            style={{ left: "68%", top: "44%" }}
          >
            <span className="num">3</span>
            <p className="bottom">helooooo</p>
          </article>

          <article
            className="linestep linestep4"
            style={{ left: "32%", top: "44.5%" }}
          >
            <span className="num">4</span>
            <p className="top">helooooo</p>
          </article>

          <article
            className="linestep linestep5"
            style={{ left: "-1.5%", top: "68%" }}
          >
            <span className="num">5</span>
            <p className="right">helooooo</p>
          </article>

          <article
            className="linestep linestep6"
            style={{ left: "21%", top: "93%" }}
          >
            <span className="num">6</span>
            <p className="bottom">helooooo</p>
          </article>

          <article
            className="linestep linestep7"
            style={{ left: "53%", top: "93%" }}
          >
            <span className="num">7</span>
            <p className="top">helooooo</p>
          </article>

          <article
            className="linestep linestep8"
            style={{ left: "86%", top: "93%" }}
          >
            <span className="num">8</span>
            <p className="bottom">helooooo</p>
          </article>

          <svg
            width="100%"
            viewBox="0 0 1156 608"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className="path"
              d="m560.30957,10.588011c0,0 438.0947,1.90476 439.04708,1.90476c0.95238,0 144.57857,-1.02912 143.80934,137.14269c-0.76923,138.17181 -116.81095,142.30859 -131.61967,143.8923c-14.80873,1.58372 -840.41472,-0.71429 -860.5941,0.71429c-20.17938,1.42858 -148.4991,6.80903 -146.83244,147.05973c1.66666,140.2507 129.52365,152.14266 129.33243,151.27321c0.19122,0.86945 815.268425,2.687632 951.42748,0"
              opacity="0.5"
              strokeWidth="7"
              stroke="#2567d1"
              fill="none"
            />
          </svg>
        </div>
      </div> */}

      </div>

      
    </>
  );
};

export default Product;
