import React, { useEffect } from 'react';
import ScrollReveal from 'scrollreveal';
import './Timeline.css';

const Timeline = () => {
  useEffect(() => {
    const sr = ScrollReveal();

    sr.reveal('.js--fadeInLeft', {
      origin: 'left',
      distance: '300px',
      easing: 'ease-in-out',
      duration: 800,
    });

    sr.reveal('.js--fadeInRight', {
      origin: 'right',
      distance: '300px',
      easing: 'ease-in-out',
      duration: 800,
    });
  }, []);

  const timelineData = [
    {
      title: 'Ideation and POC Testings',
      date: 'April 2022',
      content: 'Understanding customer pain points and validation of solution hypothesis',
      type: 'quote',
      image: '/timeline/Apr2022.jpg'
    },
    {
      title: 'MVP Preparation',
      date: 'September 2022',
      content: 'First fully electric active refrigeration unit for transportation',
      type: 'quote',
      image: '/timeline/image.png'
    },
    {
      title: 'Nexus Start-up Development Grant',
      date: 'Dec 2022',
      content: 'Chosen for the start-up development grant as a part of Nexus startup accelerator',
      type: 'quote',
      image: '/timeline/dec.png'
    },
    {
      title: 'First On-road Pilot',
      date: 'Feb 2023',
      content: 'Product validation with customers in real-operating conditions',
      type: 'quote',
      image: '/timeline/Feb2023.jpg'
    },
    {
      title: 'Incubated at IIT Madras Incubation Cell',
      date: 'May 2023',
      content: 'Shifted from Delhi to Chennai for the physical incubation at IITM Research Park',
      type: 'quote',
      image: '/timeline/May2023.jpg'
    },
    {
      title: 'First Paid-pilot Started in Chennai',
      date: 'Nov 2023',
      content: 'Started generation early revenues through demo fleet paid-pilot runs',
      type: 'quote',
      image: '/timeline/Nov2023.jpg'
    },
    {
      title: 'First 100% Electric Cold Chain Vehicle',
      date: 'Feb 2024',
      content:<div>{'100% green Cold chain EV build for the first time'}<br/>{' with TATA Ace EV'}</div> ,
      type: 'quote',
      image: '/timeline/Feb2024.jpg'
    },
    {
      title: 'Expansion of Yotuh Family and Fleet',
      date: 'June 2024',
      content: 'With more hands joining our mission, we are accelerating towards our next big milestone',
      type: 'quote',
      image: '/TeamYotuh_Photo1copy.jpg'
    }
  ];

  return (
    <div>
      <header>
        <div className="container text-center">
          <h1>Our Story</h1>
          <p>Yotuh</p>
        </div>
      </header>

      <section className="timeline">
        <div className="container">
          {timelineData.map((item, index) => (
            <div className={`timeline-item ${index % 2 === 0 ? 'js--fadeInLeft' : 'js--fadeInRight'}`} key={index}>
              <div className="timeline-img"></div>
              <div className="timeline-content">
                {item.image && <div className="timeline-img-header" style={{ backgroundImage: `url(${item.image})` }}></div>}
                <h2>{item.title}</h2>
                <div className="date">{item.date}</div>
                <blockquote>{item.content}</blockquote>
              </div>
            </div>
          ))}
        </div>
        <div className="containera text-center">
          <h1>And it Goes On ...</h1>
          {/* <p>Yotuh</p> */}
        </div>
      </section>
    </div>
  );
};

export default Timeline;
