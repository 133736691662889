import React from "react";
import "./AboutUs.css";
import ProfileCard from "./ProfileCard";
import Card from "./Card";
import Timeline from "./Timeline";
import { Link } from "react-router-dom";

const AboutUs = () => {
  const dharmikDetails = {
    img: "/Ourimg/Dharmik.png",
    team: "Axlr8r Formula Racing (2018-22)",
    roles: [
      "Team Captain'22",
      "Suspension Engineer",
      "Business plan & Budget lead",
    ],
  };

  const shaiveeDetails = {
    img: "/Ourimg/shaivee.png",
    team: "Axlr8r Formula Racing (2017-21)",
    roles: [
      "Vice Captain'21",
      "E Powertrain Engineer",
      "Operation & Sponsorship",
    ],
  };
  const vivekDetails = {
    img: "/Ourimg/vivek.png",
    team: "Axlr8r Formula Racing (2017-21)",
    roles: ["Team Captain'21", "Chassis Engineer", "technical Advancements"],
  };
  const tanmayDetails = {
    img: "/Ourimg/tanmay.png",
    team: "Axlr8r Formula Racing (2019-23)",
    roles: ["Head of Structures", "Composites & controls", "Design lead"],
  };

  return (
    <>
      <section className="about-us">
        <div className="about">
          <div className="text">
            <h2>About Us </h2>
            <h5>
              {" "}
              We are building the future of
              <span> Cold-Chain transportation</span>
            </h5>

            <img
              src="/weWithTruck11.jpg"
              alt="imgabout"
              className="pic"
            />

            <p className="aboutmob">
              With{" "}
              <strong>
                a vision to solve the climate problem by building sustainable
                technologies
              </strong>
              , Yotuh Energy was founded in 2022 by Vivek, Shaivee, and Dharmik.
              They all belong to the Formula Student community and have worked
              together for four years during their B.Tech engineering at{" "}
              <strong> IIT Delhi</strong>. As part of the{" "}
              <strong>'AXLR8R Formula Racing' </strong>
              team, they designed, developed, and built Formula Style electric
              race cars, competing in international competitions like Formula
              Student Germany, FSAE Italy, and Formula Bharat. They gained
              essential learnings in product development, EV technology,
              leadership, and mini-enterprise management, which led them to
              embark on their entrepreneurial journey after graduation.
              <br />
            </p>
            <p>
              <br />
              Yotuh Energy is revolutionizing cold chain logistics in India,
              addressing the perishable food loss problem due to inadequate cold
              chain in India. We are{" "}
              <strong>
                electrifying refrigeration systems for logistics vehicles,
                making them more accessible, affordable and sustainable
              </strong>
              . Eliminating the use of currently used fossil fuel driven system,
              our solution is a vehicle independent refrigeration systems which
              is powered by Electricity. Our technology{" "}
              <strong>cuts the refrigeration running costs by 75%</strong>,
              boosting customer profits. Each unit saves{" "}
              <strong>3 to 36 tonnes of CO2</strong> annually. We are the first
              one to enable small cargo vehicles and EVs to meet intra-city
              perishable food delivery demands.
              <br />
              <br />
              We are a bunch of hustlers, hackers and hipsters who are
              passionate about problem solving by building the technologies. We
              have a very young and diverse team who complements each other in
              terms of knowledge and skills. With the guidance of our
              experienced advisors and mentors, we are progressing fast on our
              journey to take the <strong>cold chain from Grey to Green</strong>
              .
              <br />
            </p>
          </div>
        </div>
      </section>

      <h2
        style={{
          fontSize: "40px",
          textAlign: "center",
          paddingTop: "50px",
          paddingBottom: "30px",
        }}
      >
        Founders{" "}
      </h2>
      <div className="members">
        <a
          href="https://www.linkedin.com/in/vivek-mahindrakar/"
          target="_blank"
          rel="noopener noreferrer"
          className="profile-link"
        >
          <ProfileCard
            img={vivekDetails.img}
            name="Vivek Mahindrakar"
            title="Co-founder"
            roleDetails={vivekDetails}
            degree="B.Tech'2021 @ IIT Delhi"
          />
        </a>
        <a
          href="https://www.linkedin.com/in/dharmikbapodara/"
          target="_blank"
          rel="noopener noreferrer"
          className="profile-link"
        >
          <ProfileCard
            img={dharmikDetails.img}
            name="Dharmik Bapodara"
            title="Co-founder"
            roleDetails={dharmikDetails}
            degree="B.Tech'2022 @ IIT Delhi"
          />
        </a>
        <a
          href="https://www.linkedin.com/in/shaiveemalik/"
          target="_blank"
          rel="noopener noreferrer"
          className="profile-link"
        >
          <ProfileCard
            img={shaiveeDetails.img}
            name="Shaivee Malik"
            title="Co-founder"
            roleDetails={shaiveeDetails}
            degree="B.Tech'2021 @ IIT Delhi"
          />
        </a>
      </div>

      <h2
        style={{
          fontSize: "30px",
          textAlign: "center",
          paddingTop: "50px",
          paddingBottom: "30px",
        }}
      >
        Core Team{" "}
      </h2>
      <div className="smallmembers">
        <a
          href="https://www.linkedin.com/in/tanmay-gupta-5a6174201/"
          target="_blank"
          rel="noopener noreferrer"
          className="profile-link"
        >
          <div class="profile-cards">
            <Card
              image="/Ourimg/Tanmay_Por_Final.jpg"
              name="Tanmay Gupta"
              title="Head - System Intelligence"
              description={
                <>
                  B.Tech'2023 @ IIT Delhi
                  <br /> Axlr8r Formula Racing (2019-23)
                </>
              }
            />
          </div>
        </a>
        <a
          href="https://www.linkedin.com/in/gunall01/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
          target="_blank"
          rel="noopener noreferrer"
          className="profile-link"
        >
          <div class="profile-cards">
            <Card
              image="/Ourimg/Gunall_Por_Final.jpg"
              name="Gunall Suresh"
              title="Mechanical Engineer"
              description={
                <>
                  B.Tech - PSG Tech Coimbatore
                  <br />
                  Team Pegasus racing
                </>
              }
            />
          </div>
        </a>

        <a
          href="https://www.linkedin.com/in/sanskruti-khobragade-bb233a212/"
          target="_blank"
          rel="noopener noreferrer"
          className="profile-link"
        >
          <div class="profile-cards" style={{ fontSize: "1.9vh" }}>
            <Card
              image="/Ourimg/Sankruti_Por_Final.jpg"
              name="Sanskruti Khobragade"
              title="Embedded Hardware engineer"
              description={
                <>
                  B.Tech - St. Vincent Pallotti Tech Nagpur
                  <br />
                  E-formula Ashwariders
                </>
              }
            />
          </div>
        </a>

        <a
          href="https://www.linkedin.com/in/bhavesh-bhati-100577143/"
          target="_blank"
          rel="noopener noreferrer"
          className="profile-link"
        >
          <div class="profile-cards" style={{ fontSize: "2vh" }}>
            <Card
              image="/Bhaveshnew11.jpg"
              name="Bhavesh Bhati"
              title="Business Development Exe."
              description={
                <>
                  B.Tech'2024 @ IIT Delhi
                  <br /> Axlr8r Formula Racing (2020-24)
                </>
              }
            />
          </div>
        </a>

        <a
          href="https://www.linkedin.com/in/david-04b210146/"
          target="_blank"
          rel="noopener noreferrer"
          className="profile-link"
        >
          <div class="profile-cards">
            <Card
              image="/Ourimg/David_Final.jpg"
              name="David"
              title="Modeling & control engineer"
              description={
                <>
                  B.Tech'2024 @ IIT Delhi
                  <br /> Axlr8r Formula Racing (2020-24)
                </>
              }
            />
          </div>
        </a>
      </div>

      <h2
        style={{
          fontSize: "40px",
          textAlign: "center",
          paddingTop: "50px",
          paddingBottom: "30px",
        }}
      >
        Advisor and Mentor{" "}
      </h2>

      <div className="adv">
        <a
          href="https://www.linkedin.com/in/vinod-k-dasari-7bb0918/"
          target="_blank"
          rel="noopener noreferrer"
          className="profile-link"
        >
          <div class="profile-cards" style={{ fontSize: "2vh" }}>
            <Card
              image="/Ourimg/Vinod-K-Dasari-as-the-Chairman-of-the-Board.png"
              name="Mr. Vinod K Dasari"
              title={`Advisor\n`}
              description={
                <>
                  Ex. MD of Ashok Layland
                  <br />
                  Ex. CEO - Royal Enfield
                </>
              }
            />
          </div>
        </a>
        <a
          href="https://www.ee.iitm.ac.in/~ashok/index.html"
          target="_blank"
          rel="noopener noreferrer"
          className="profile-link"
        >
          <div class="profile-cards" style={{ fontSize: "2vh" }}>
            <Card
              image="/Ourimg/Prof. Jhunjhunwala.jpeg.jpg"
              name="Prof. Ashok Jhunjhunwala"
              title={`Mentor\n`}
              description={
                <>
                  Padmashri Awardee and
                  <br />
                  President of IITM Research Park
                </>
              }
            />
          </div>
        </a>
        <a
          href="https://www.linkedin.com/in/dr-kaushal-jha/"
          target="_blank"
          rel="noopener noreferrer"
          className="profile-link"
        >
          <div class="profile-cards" style={{ fontSize: "1.8vh" }}>
            <Card
              image="/Ourimg/Dr. Kaushal Kumar Jha.jpeg.jpg"
              name="Dr. Kaushal Kumar Jha"
              title={`Mentor\n`}
              description={
                <>
                  CEO CEET, IIT Madras
                  <br />
                  Professor at IIT Madras
                </>
              }
            />
          </div>
        </a>
      </div>
      <h2
        style={{
          textAlign: "center",
          paddingTop: "50px",
          paddingBottom: "30px",
        }}
        className="OurBack"
      >
        Our Background{" "}
      </h2>
      <a
          href="https://automobileclub.iitd.ac.in/"
          target="_blank"
          rel="noopener noreferrer"
          className="profile-link"
        >
      <div className="classforimg">
        <div className="product-new-image-container">
        <img src="/axl8r.jpg" alt="axl8r" style={{ width: "100%" }} />
          
          <div className="overlay">
            {/* <div className="icon" title="User Profile">
              Hello
            </div> */}
            <div
              className="parapdt1"
              style={{ padding: "5%", fontSize: "20px" }}
            >
              <h3
                style={{
                  textAlign: "center",
                  color: "white",
                }}
                className="axlhead"
              >
                Axlr8r Formula Racing
              </h3>

              <p
                style={{ textAlign: "center", color: "white" }}
                className="axl8text"
              >
                Our story began way before Yotuh Energy. While Vivek, Shaivee
                and Dharmik were pursuing their engineering in IIT Delhi, they
                all met in the formula team of their college - AXLR8R Formula
                Racing. This experience tested and honed their skills in
                engineering, design, manufacturing, and business, preparing them
                for real-world challenges. The team managed all aspects of the
                project with support from academia and industry, fostering
                persistence and innovation. The rigorous environment of AXLR8R
                Formula Racing was instrumental in shaping their professional
                readiness. Leveraging this experience, they embarked on their
                entrepreneurial journey, eventually founding Yotuh Energy with
                additional team members.
              </p>
            </div>
          </div>
        </div>
      </div>
      </a>
      <Timeline></Timeline>
    </>
  );
};

export default AboutUs;
