import React from "react";
import MapComponent from "../Mapcomponent";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";

const Iotdashboard = () => {
  return (
    <>
      hi
    </>
  );
};

export default Iotdashboard;
