import React from 'react';
import './Card.css';

const Card = ({ image, name, title, description }) => {
  return (
    <div className="card">
      <img src={image} alt={name} className="card-image"/>
      <div className="card-content">
        <h2 className="card-name">{name}</h2>
        <h3 className="card-title">{title}</h3>
        <p className="card-description">{description}</p>
      </div>      
    </div>
  );
};

export default Card;
