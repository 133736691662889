const awsExports = {
  REGION: process.env.REACT_APP_REGION,
  USER_POOL_ID: process.env.REACT_APP_POOL_ID,
  USER_POOL_APP_CLIENT_ID: process.env.REACT_APP_CLIENT_ID
};

// export { awsExports };
const amplifyConfig = {
  Auth: {
    Cognito: {
      userPoolId: process.env.REACT_APP_POOL_ID,
      userPoolClientId: process.env.REACT_APP_CLIENT_ID,
      region: process.env.REACT_APP_REGION,
      loginWith: {
        oauth: {
          scopes: [
            'phone',
            'email',
            'profile',
            'openid',
            'aws.cognito.signin.user.admin'
          ],
          redirectSignIn: [window.location.origin],
          redirectSignOut: [window.location.origin],
          responseType: "code",
        },
      },
    },
  },
};

export { awsExports, amplifyConfig };
