// src/ProfileCard.js
import React from 'react';
import './ProfileCard.css';

const ProfileCard = ({ img, name, title, roleDetails, degree }) => {
  return (
    <div className="profile-card">
      <div className="profile-picture"><img src={img} alt={`${name}'s profile`} /></div>
      <div className="profile-details">
        <h2>{name}</h2>
        <h3>{title}</h3>
        <div className="profile-role">
          <h4>{degree}</h4>
          {/* <ul>
            {roleDetails.roles.map((role, index) => (
              <li key={index}>{role}</li>
            ))}
          </ul> */}
        </div>
        <p>{roleDetails.team}</p>
      </div>
    </div>
  );
};

export default ProfileCard;
