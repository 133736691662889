import React from "react";

const PrivacyPolicy = () => {
  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img
          src="/Yotuh Logo (1).png"
          className="logo-img mobile"
          alt="logo"
          style={{ height: "200px" }}
        />
      </div>
      <div
        style={{
          textAlign: "justify",
          margin: "20px",
          fontFamily: "Arial, sans-serif",
        }}
      >
        <h1 style={{ padding: "20px", textAlign: "center" }}>
          Website privacy policy -Yotuh Energy
        </h1>
        <p>
          <strong>Last updated on: July 16th, 2024</strong>
        </p>
        <p style={{ maxWidth: "80%", margin: "0 auto", textAlign: "justify" }}>
          Yotuh Energy Private Limited, a private limited company, incorporated
          under the provisions of the Companies Act, 2013, having its CIN
          U29109DL2022PTC402697 and its Affiliates/Subsidiaries/Associates
          (hereinafter referred to as <strong>“We”, “Us”, “Our”,</strong> or{" "}
          <strong>“Company”</strong> or
          <strong>“Yotuh”</strong>). Yotuh owns, operates and manages a website
          having domain name <a href="https://yotuh.com">https://yotuh.com</a>{" "}
          under the name and style of “Yotuh” (hereinafter referred to as
          “Platform”).
        </p>
        <p style={{ maxWidth: "80%", margin: "0 auto", textAlign: "justify" }}>
          For the purposes of this privacy policy (hereinafter referred to as
          “Privacy Policy” or “Policy”) wherever the context so requires,
          references to the terms “User(s)”, “You” or “Your”, shall mean and
          include the user, registered on the Platform, who uses or accesses the
          Platform. This Privacy Policy is published in compliance with the
          Information Technology Act, 2000, and applicable amendments, rules,
          regulations and guidelines enacted thereunder from time to time{" "}
          <strong>(“IT Act, 2000”)</strong> with specific mention of regulation
          4 of the Information Technology (Reasonable Security Practices and
          Procedures and Sensitive personal information) Rules, 2011, and any
          other national and state laws which relate to the processing of data.
          This Privacy Policy is an electronic record in terms of the IT Act and
          this electronic record is generated by a computer system and does not
          require any physical or digital signatures. Any new features and/or
          services that are added to our current service at any point in the
          future shall also be subject to the terms set out in this Policy along
          with any other future relevant legislations to be incorporated as per
          the laws of the land. This Privacy Policy shall apply to any person
          who visits, browses, uses or accesses the Platform or use any Services
          on the Platform.
        </p>
        <p style={{ maxWidth: "80%", margin: "0 auto", textAlign: "justify" }}>
          This Privacy Policy, inter alia, states: (i) the type of information
          collected from the Users, including personal information and sensitive
          personal data or information (as defined below), relating to an
          individual; (ii) the purpose, means and modes of collection, usage,
          processing, retention and destruction of such information; (iii) how
          and to whom We will disclose such information; and (iv) how We will
          protect the Users’ personal information when they access the Platform.
        </p>
        <h2 style={{ padding: "20px", textAlign: "center" }}>
          Purpose and Scope
        </h2>
        <p style={{ maxWidth: "80%", margin: "0 auto", textAlign: "justify" }}>
          This Privacy Policy is published to inform and describe Our Users of
          Our policies and procedures regarding collection, storage, and
          processing and sharing of information that We receive from our Users
          on the Platform or those which are stored or accessed by Us at the
          time of registering and/or availing of the Services on the Platform.
          We have developed this Policy to familiarize You with Our practices
          and demonstrate Our commitment to the protection of Your privacy and
          Your personal information. With regard to this Privacy Policy, the
          terms “using”, and “processing” information include, without
          limitation; the use of cookies; and the collection, storage, transfer,
          evaluation, deletion, disclosure, management, handling, modifying and
          use of information.
        </p>
        <p style={{ maxWidth: "80%", margin: "0 auto", textAlign: "justify" }}>
          The below mentions the outlined idea of Scope of the Privacy Policy:
          <ul>
            <li>
              This Privacy Policy is applicable to all Users of this Platform.
              By using the Services, registering with Us or by otherwise giving
              Us Your information, You shall be deemed to have read, understood
              and agreed to the practices and policies outlined in this Privacy
              Policy and agree to be bound by the terms hereof as laid down.
            </li>
            <li>
              We respect Your need to understand how and why information is
              being collected, used, disclosed, transferred and stored. This
              Privacy Policy sets out the way in which We process Your
              information when You visit the Platform or use the Services in
              accordance with applicable relevant data protection laws. By
              accessing Our Platform or availing the Services, You agree to be
              bound by the terms of this Privacy Policy.
            </li>
            <li>
              It is important that You read this Privacy Policy together with
              any or all other policies We may provide on specific occasions
              when We are collecting or processing your personal information, so
              that You are fully aware of how and why We are using your personal
              information.
            </li>
            <li>
              This Privacy Policy applies only to information that You provide
              Us via the Platform. We reserve the right to update this Privacy
              Policy from time to time to reflect any changes to Our Services.
              You may note that this Policy itself and any such change of policy
              will be effective from the date of posting on the site. Any
              changes shall be effective immediately upon the posting of the
              revised Privacy Policy. While We will make reasonable efforts to
              keep You posted on any updates to this Privacy Policy, to make
              sure that You are aware of any changes, We recommend that You
              review this policy periodically. If You are not comfortable with
              any of the changes to Privacy Policy, You may choose to
              discontinue usage of the Platform. You can also email us at
              <strong>help@yotuh.com </strong>to update or delete Your personal
              information that Yotuh has collected.
            </li>
            <li>
              The information contained on this Platform and the information
              collected by using/login and /or accessing this Platform are
              stored at a secured server. It is stated by the server service
              provider that they have all the best security practices required
              for the server. The Platform is owned by Us and is located in
              India. Hence, We are bound by duty to abide by the laws, including
              but not limited to, regulations, rules, circulars and
              notifications governing privacy in India.
            </li>
            <li>
              This Privacy Policy does not apply to any third-party website(s)
              and mobile app(s). You are requested to take note that information
              and privacy practices of Our business partners, advertisers,
              sponsors or other sites to which we provide hyperlink(s), may be
              different from this Policy. Hence, it is recommended that you
              review the Privacy Policy of any such third parties before you
              interact with such interfaces. Under such circumstances we kindly
              recommend you to read the policies on the applicable/relevant
              websites or sources.
            </li>
          </ul>
          You hereby consent to us collecting, using and sharing and disclosing
          your information as described in this Privacy Policy. If You do not
          agree with this Privacy Policy at any time, please do not use any of
          the Services, use or access the Platform or give us any of your
          information thereof.
        </p>
        <h2 style={{ padding: "20px", textAlign: "center" }}>
          Collection of Personal Information
        </h2>

        <p style={{ maxWidth: "80%", margin: "0 auto", textAlign: "justify" }}>
          A. When you access our Platform, You may provide us with Your Personal
          Information, including when you register an account with us or sign up
          to receive announcements or other information from Us. Such
          information may include your personal details such as Name, Date of
          Birth/Age and Gender; Contact Details such as Residential Address,
          contact number, email address; Your usage details with regards to the
          Platform, such as time, frequency, duration and pattern of use,
          features used, and any other information that is willingly shared by
          You. 
        </p>

        

        <p style={{ maxWidth: "80%", margin: "0 auto", textAlign: "justify" }}>
          B. We may also obtain information about you from other sources and we
          may add and maintain such information along with the information about
          you obtained through the Platform.
        </p>

        <p style={{ maxWidth: "80%", margin: "0 auto", textAlign: "justify" }}>
          C. To improve the responsiveness of the Platform, as you navigate
          through the website, we collect your computer operating system type,
          browser type, browser language, the websites you visited previously,
          pages you viewed, time spent on each page, access times and
          information about your use and actions on our site. Certain other
          information is also passively collected (that is, gathered without
          your actively providing the information) using various technologies
          and means, such as internet protocol addresses, cookies, internet
          tags, and navigational data collection. Navigational data is used for
          system management, to improve the content of the site, market research
          purposes, and to communicate information to visitors. Cookies are
          small data files stored on your hard drive by a website. We may use
          both session cookies (which expire once you close your web browser)
          and persistent cookies (which stay on your computer until you delete
          them) to provide you with a more personal and interactive experience
          when using the Website. This type of information is collected to make
          the Website more useful to you and to tailor the experience with us to
          meet your interests and needs.
        </p>

        <p style={{ maxWidth: "80%", margin: "0 auto", textAlign: "justify" }}>
          E. You are responsible for maintaining the accuracy of the information
          you submit to us, such as your contact information. If you provide any
          information that is untrue, inaccurate, out of date or incomplete (or
          becomes untrue, inaccurate, out of date or incomplete), or if Yotuh
          has reasonable grounds to suspect that the information provided by you
          is untrue, inaccurate, out of date or incomplete, Yotuh shall
          disregard and delete such at its sole discretion, and may bar/restrict
          you from using or accessing its Website in the future. Yotuh shall
          retain the right not to correct, delete or update your Personal
          Information, including (a) where the Personal Information is opinion
          data that is kept solely for evaluative purpose; and (b) the Personal
          Information is in documents related to a prosecution if all
          proceedings relating to the prosecution have not been completed.
        </p>

        <h2 style={{ padding: "20px", textAlign: "center" }}>
          Use of Personal Information
        </h2>

        <p style={{ maxWidth: "80%", margin: "0 auto", textAlign: "justify" }}>
          You hereby provide Us with Your explicit permission to use any and all
          of Your personal Information collected by Us in order to improve our
          services, website and how we operate our businesses; understand and
          enhance your experience using our website, products and services;
          provide and deliver products and services You request; provide
          customer support and respond to your requests, comments and inquiries;
          send you related information, including confirmations, invoices,
          technical notices, updates, security alerts and support and
          administrative messages; communicate with you about promotions,
          upcoming events and news about our products and services; We may
          process your Personal Information without your knowledge or consent
          where required by applicable law or regulation for the purposes of
          verification of identity or for prevention, detection or
          investigation, including of cyber incidents, prosecution and
          punishment of offences; and to protect, investigate and deter against
          fraudulent, unauthorized or illegal activity.
        </p>
        <h2 style={{ padding: "20px", textAlign: "center" }}>
          Access, Retention and Deletion of Personal Information
        </h2>
        <p style={{ maxWidth: "80%", margin: "0 auto", textAlign: "justify" }}>
          A. Yotuh will take reasonable steps to accurately record the Personal
          Information that You provide to Yotuh and any subsequent updates. As a
          User, you have the right to access and correct your Personal
          Information that Yotuh holds.
        </p>
        <p style={{ maxWidth: "80%", margin: "0 auto", textAlign: "justify" }}>
          B. You can follow the "unsubscribe" instructions in commercial email
          messages. If required by you, Yotuh will delete the Personal
          Information provided by you, but Yotuh may retain information about
          you for the purposes authorized under this Privacy Policy and for the
          duration as mentioned in this Privacy Policy, unless prohibited by
          law. Thereafter, Yotuh will either delete your Personal Information or
          de-identify it so that it is anonymous and not attributed to your
          identity. Upon deletion of your Personal Information, copies of your
          Personal Information may remain viewable in cached and archived pages.
        </p>
        <h2 style={{ padding: "20px", textAlign: "center" }}>
          Sharing of Personal Information
        </h2>
        <p style={{ maxWidth: "80%", margin: "0 auto", textAlign: "justify" }}>
          A. We shall not disclose your Personal Information to third parties
          except as mentioned herein:
          <ul>
            <li>At your direction to disclose such information;</li>
            <li>
              We may share or disclose your non-private, aggregated or otherwise
              non-personal information with third parties in compliance with the
              terms of this Privacy Policy and the applicable laws.
            </li>
            <li>
              Information that we collect from our Users, including Personal
              Information, is considered to be a business asset. As a result, if
              we are acquired as a result of a transaction such as a merger,
              acquisition or asset sale or if we go out of business or enter
              bankruptcy, your Personal Information may be disclosed or
              transferred to the third-party acquirer in connection with the
              transaction.
            </li>
            <li>
              We may share or disclose your Personal Information for legal,
              protection and safety purposes, including to comply with laws, to
              respond to lawful requests and legal processes, and to protect the
              rights and property of our company, agents, customers and others,
              which includes enforcing our agreements, policies, and terms of
              service. We may share information in an emergency, which includes
              protecting the safety of our employees, our customers, or any
              other person, however in compliance with applicable laws.
            </li>
          </ul>
        </p>
        <h2 style={{ padding: "20px", textAlign: "center" }}>
          Collection and Use of Non-Personal Data
        </h2>
        <p style={{ maxWidth: "80%", margin: "0 auto", textAlign: "justify" }}>
          Non-personal data is data that can never be used to identify an
          individual. We may collect information regarding customer activities
          on our Platform. This aggregated information shall be used by Us in
          research, analysis, to improve and monitor our services and for
          various promotional schemes and offers. Such non-personal data may be
          shared in aggregated, non-personal form with third party to enhance
          customer experience, offerings or services. The Platform uses cookies,
          and while using the Platform You will be prompted to accept all
          cookies.
        </p>
        <h2 style={{ padding: "20px", textAlign: "center" }}>
          Retention of Personal Information
        </h2>
        <p style={{ maxWidth: "80%", margin: "0 auto", textAlign: "justify" }}>
          We may retain Your personal information for as long as necessary to
          fulfill the purposes outlined in this Privacy Policy, unless a longer
          retention period is required or permitted by applicable laws,
          including but not limited to IT Act, 2000 and its relevant Rules. This
          may include without limitation, satisfying any legal, regulatory,
          accounting, reporting requirements, to carry out legal work, for the
          establishment or defence of legal claims, etc.
        </p>
        <h2 style={{ padding: "20px", textAlign: "center" }}>
          Policy Towards Children
        </h2>
        <p style={{ maxWidth: "80%", margin: "0 auto", textAlign: "justify" }}>
          Yotuh does not knowingly collect or solicit personal information from
          anyone under the age of 18. If you are under 18, please do not attempt
          to register for the Services or send any personal information about
          yourself to Yotuh. If Yotuh learns that we have collected personal
          information from a person under age 18, we will delete that
          information as quickly as possible. If you believe that a person under
          18 may have provided us with personal information, please contact us
          at{" "}
          <a href="mailto:help@yotuh.com">
            <strong>help@yotuh.com</strong>
          </a>
          .
        </p>
        <h2 style={{ padding: "20px", textAlign: "center" }}>
          Choices and Changes
        </h2>
        <p style={{ maxWidth: "80%", margin: "0 auto", textAlign: "justify" }}>
          Non-marketing emails include emails about your accounts and our
          business dealings with you. You may send requests about your Personal
          Information to our contact information below. You can request to
          change contact choices, opt-out of certain information sharing with
          others, and update your Personal Information. You can typically remove
          and reject cookies from our site with your browser settings. Many
          browsers are set to accept cookies until you change your settings. If
          you remove or reject our cookies, it could affect how our site works
          for you. You may at any time also intimate to us that you wish to have
          your data erased from our servers/records. We will in such
          circumstance comply and within reasonable timeframes have such data
          removed from our servers.
        </p>
        <h2 style={{ padding: "20px", textAlign: "center" }}>
          Security and Confidentiality of Personal Information
        </h2>
        <p style={{ maxWidth: "80%", margin: "0 auto", textAlign: "justify" }}>
          A. Yotuh takes all necessary precautions to protect your Personal
          Information both online and off-line, and implements reasonable
          security practices and measures including certain managerial,
          technical, operational and physical security control measures in order
          to prevent unauthorized access, disclosure or use of your Personal
          Information.
        </p>
        <p style={{ maxWidth: "80%", margin: "0 auto", textAlign: "justify" }}>
          B. Please be aware, however, that these security measures do not
          guarantee that your information will not be accessed, compromised,
          corrupted, disclosed or destroyed. Accordingly, we cannot guarantee
          the absolute security of any information.
        </p>
        <p style={{ maxWidth: "80%", margin: "0 auto", textAlign: "justify" }}>
          C. By using the Platform, you acknowledge that you understand and
          agree to assume these risks. Your Personal Information may be stored
          on the servers provided to us by third parties (based on separate
          agreements containing security related clauses in accordance with
          applicable laws)
        </p>
        <p style={{ maxWidth: "80%", margin: "0 auto", textAlign: "justify" }}>
          D. Yotuh makes all User information accessible to its employees,
          agents or partners and third parties only on a need-to-know basis, and
          binds only its employees to strict confidentiality obligations. Yotuh
          shall not rent or sell potentially personally-identifying and
          personally-identifying information to anyone. Other than to its
          employees, contractors and affiliated organizations, as described
          above, Yotuh discloses potentially personally-identifying and
          personally-identifying information only in response to a subpoena,
          court order or other governmental request, or when Yotuh believes in
          good faith that disclosure is reasonably necessary to protect the
          property or rights of Yotuh, third parties or the public at large.
        </p>
        <p style={{ maxWidth: "80%", margin: "0 auto", textAlign: "justify" }}>
          E. Yotuh is not responsible for the confidentiality, security or
          distribution of your Personal Information by our partners and third
          parties outside the scope of our agreement with such partners and
          third parties. Further, Yotuh shall not be responsible for any breach
          of security or for any actions of any third parties or events that are
          beyond the reasonable control of Yotuh including but not limited to,
          acts of government, computer hacking, unauthorized access to computer
          data and storage device, computer crashes, breach of security and
          encryption, poor quality of Internet service or telephone service of
          the User etc.
        </p>
        <h2 style={{ padding: "20px", textAlign: "center" }}>Disclaimer</h2>
        <p style={{ maxWidth: "80%", margin: "0 auto", textAlign: "justify" }}>
          We respect the exercise of the rights You have in relation to the
          personal data and information We process or use. You can request
          access to or a copy of your Personal Information collected and
          processed by Us. You may also request the rectification and removal of
          personal data and information or the restriction of the processing of
          Your personal data and information. Users have the right to
          unilaterally change their e-mail and contact preferences at any time
          by logging into their Account on the Platform and changing the account
          settings.
        </p>
        <p style={{ maxWidth: "80%", margin: "0 auto", textAlign: "justify" }}>
          You are responsible for maintaining the accuracy, truthfulness,
          correctness and completeness of the information You submit or provide
          to Us, on the Platform; and shall hold harmless and indemnify Us from
          and against all claims arising out of or in relation to the wrongful
          information posted, provided by You to Us, on the Platform. If Your
          personal information changes, You may correct, delete inaccuracies, or
          amend information by making the change on Your Account information
          page or by contacting Us on the contact information given below.
        </p>
        <h2 style={{ padding: "20px", textAlign: "center" }}>Support</h2>
        <p style={{ maxWidth: "80%", margin: "0 auto", textAlign: "justify" }}>
          If you have any question or complaints about the processing of your
          personal information, write to us at{" "}
          <a href="mailto:help@yotuh.com">help@yotuh.com</a>.
        </p>
        <h2 style={{ padding: "20px", textAlign: "center" }}>
          Grievance Officer
        </h2>
        <p style={{ maxWidth: "80%", margin: "0 auto", textAlign: "justify" }}>
          In accordance with the provisions of the IT Act, 2000, and rules made
          thereunder, the name and contact details of the Grievance Officer are
          provided below:
        </p>
        <p style={{ maxWidth: "80%", margin: "0 auto", textAlign: "justify" }}>
          <strong>Attention:</strong> Mr. Tanmay Gupta
          <br />
          <strong>Designation:</strong> Head of System Intelligence
          <br />
          <strong>Email:</strong>{" "}
          <a href="mailto:Help@yotuh.com">Help@yotuh.com</a>
          <br />
          <strong>Timings:</strong> 10.00am to 7.00pm, Monday to Saturday
          <br />
          <strong>Phone:</strong> +91-9673629774
        </p>
      </div>
    </>
  );
};

export default PrivacyPolicy;
