import React, { useState } from 'react';
import AWS from 'aws-sdk';
import './Order.css';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID, // Use REACT_APP_ prefix for environment variables in React
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
});

const ses = new AWS.SES({ apiVersion: '2010-12-01' });

const Order = () => {
  const [formData, setFormData] = useState({
    name: '',
    company: '',
    phoneNo: '',
    containerLength: '',
    temperatureSetPoint: '',
    senderEmail: '',
    cityState: '',
    requirements: '',
    comments: '',
  });

  const [message, setMessage] = useState(null);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form Data Submitted: ', formData);

    const params = {
      Destination: {
        ToAddresses: [process.env.REACT_APP_SES_TO_EMAIL], // Ensure this email is verified in the SES console
      },
      Message: {
        Body: {
          Text: {
            Data: `Name: ${formData.name}\nCompany: ${formData.company}\nPhone No: ${formData.phoneNo}\nContainer Length: ${formData.containerLength}\nTemperature Set Point: ${formData.temperatureSetPoint}\nCity, State: ${formData.cityState}\nRequirements: ${formData.requirements}\nComments: ${formData.comments}`,
          },
        },
        Subject: {
          Data: 'New Waitlist Submission',
        },
      },
      Source: process.env.REACT_APP_SES_FROM_EMAIL, // Use your verified email address
      ReplyToAddresses: [formData.senderEmail], // This sets the visitor's email as the reply-to address
    };

    ses.sendEmail(params, (err, data) => {
      if (err) {
        console.error('Error sending email:', err);
        setMessage('Failed to send email');
      } else {
        console.log('Email sent successfully:', data);
        setMessage('Email sent successfully');
      }
    });
  };

  return (
    <div style={{ backgroundColor: "#f5ffff" }}>
      <div className="form-container">
        <form onSubmit={handleSubmit} className="form">
          <h2>Join Waitlist</h2>
          <p>Send us your truck details</p>
          <label>
            Name
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Company
            <input 
              type="text" 
              name="company" 
              value={formData.company} 
              onChange={handleChange} 
              required 
            />
          </label>
          <label>
            Phone No
            <input 
              type="text" 
              name="phoneNo" 
              value={formData.phoneNo} 
              onChange={handleChange} 
              required 
            />
          </label>
          <label>
            Your Email
            <input
              type="email"
              name="senderEmail"
              value={formData.senderEmail}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            City, State
            <input
              type="text"
              name="cityState"
              value={formData.cityState}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Requirements
            <input
              type="text"
              name="requirements"
              value={formData.requirements}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Comments
            <input
              type="text"
              name="comments"
              value={formData.comments}
              onChange={handleChange}
            />
          </label>
          <button type="submit">Submit</button>
        </form>
        {message && (
          <Alert icon={<CheckIcon fontSize="inherit" />} severity="success" style={{ margin: "2%" }}>
            {message}
          </Alert>
        )}
      </div>
    </div>
  );
};

export default Order;
